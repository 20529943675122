import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import SignInLayout from 'components/SignInLayout';

import { ReactComponent as IconParrotPOS } from 'images/svg/brand/parrot_connect_large.svg';

import { removeAuthToken, removeCookieUser } from 'utils/auth';

import useStyles from './styles';

function SignOut() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    removeAuthToken();
    removeCookieUser();
    history.push('/signin');
  });

  return (
    <SignInLayout>
      <div className={classes.header}>
        <IconParrotPOS className={classes.parrotS} />
      </div>

      <Box mb={2} mt={4}>
        <Typography gutterBottom variant="h6">
          {t('common:auth.signingOut')}
        </Typography>
      </Box>

      <Box mb={2} textAlign="center" width="80%">
        <CircularProgress />
      </Box>
    </SignInLayout>
  );
}

export default SignOut;
