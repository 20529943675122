import React from 'react';
import PropTypes from 'prop-types';

import { LocalizationProvider } from '@mui/x-date-pickers';

import ViewPanelProvider from 'providers/root/ViewPanelProvider';
import ContentLayoutProvider from 'providers/root/ContentLayoutProvider';
import SidebarLayoutProvider from 'providers/root/SidebarLayoutProvider';

import ComposeProviders from 'components/ComposeProviders';

interface Props {
  children: React.ReactNode;
}

function AppProviders({ children }: Props) {
  return (
    <LocalizationProvider>
      <ComposeProviders providers={[<ContentLayoutProvider />, <ViewPanelProvider />, <SidebarLayoutProvider />]}>
        {React.Children.only(children)}
      </ComposeProviders>
    </LocalizationProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProviders;
