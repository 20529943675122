import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from 'hooks/useAuth';
import history from 'utils/history';

export function HomeRedirect() {
  const { isAuthenticated } = useAuth();

  const pathDefaultRedirect = () => {
    if (isAuthenticated) {
      return '/settings';
    }

    if (!isAuthenticated) {
      return '/signin';
    }

    return '/permissions-denied';
  };

  history.push(pathDefaultRedirect());

  return (
    <Backdrop open>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}
