import { useHistory, useLocation } from 'react-router';

/**
 * useQueryParams
 * is a hook that returns the query params of the current url
 * and has two functions to update the query params
 *
 * setQueryParam('key', 'value')
 *
 * setManyQueryParams({
 *   key: 'value',
 *   key2: 'value2'
 * })
 *
 */
export default function useQueryParams<T>() {
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(location.search);
  const queryParams = (Object.fromEntries(urlSearchParams) as unknown) as Partial<T>;

  function setQueryParam(key: keyof T, value: string | number) {
    urlSearchParams.set(String(key), String(value));

    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  }

  function setManyQueryParams(params: Partial<T>) {
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === 'object') {
        const arrayOfValues = value as string[];
        urlSearchParams.delete(key);
        arrayOfValues.forEach(currentValue => urlSearchParams.append(key, String(currentValue)));
      } else {
        urlSearchParams.set(key, String(value));
      }
    });

    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  }

  function clearQueryParams() {
    history.replace({ pathname: location.pathname, search: '' });
  }

  return { queryParams, setQueryParam, setManyQueryParams, clearQueryParams };
}
