import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpPhone from '@experimental-components/IconsComponents/HelpPhone';
import HelpEmail from '@experimental-components/IconsComponents/HelpEmail';
import HelpChat from '@experimental-components/IconsComponents/HelpChat';
import Fade from '@mui/material/Fade';

import useEnvironment from 'hooks/useEnvironment';

import useStyles from './styles';

function SidebarLayoutItemMenu({ disabled, label, startIcon, endIcon, openMenu, t, ...others }) {
  const { isTestEnv } = useEnvironment();

  const StyledMenu = withStyles(theme => ({
    paper: { backgroundColor: isTestEnv ? theme.palette.testEnv.main : '#262626' },
  }))(props => (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      elevation={0}
      getContentAnchorEl={null}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles(theme => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.eclipse,
      },
    },
  }))(MenuItem);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <li className={classes.item} data-testid="navLinkItem">
      <Button
        classes={{ [startIcon && 'label']: classes.navLinkStartIcon }}
        className={clsx(classes.navLink, {
          [classes.navLinkEndIcon]: endIcon,
          [classes.navLinkStartIcon]: startIcon,
          [classes.disabled]: disabled,
          [classes.collapseMenu]: !openMenu,
        })}
        disabled={disabled}
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={handleClick}
        {...others}
      >
        {startIcon && <span>{startIcon}</span>}
        {openMenu ? (
          <>
            <span className={classes.navLinkLabel} data-testid="navLinkLabel" style={{ textAlign: 'initial' }}>
              {label}
            </span>
            {endIcon && endIcon}
          </>
        ) : null}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: openMenu ? 270 : 68,
        }}
        className={classes.helpMenu}
        data-testid="navLinkItemMenu"
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Fade}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <HelpChat color="#fff" size={24} />
          </ListItemIcon>
          <ListItemText>
            <Box
              className={classes.menuLink}
              component="a"
              href="https://parrotsoftware.zendesk.com/hc/es"
              target="_blank"
            >
              {t('common:help.helpDesk')}
            </Box>
          </ListItemText>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <HelpPhone color="#fff" size={24} />
          </ListItemIcon>
          <ListItemText>
            <Box className={classes.menuLink} component="a" href="tel:+8120466363">
              {t('common:help.phone')}
            </Box>
          </ListItemText>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <HelpEmail color="#fff" size={24} />
          </ListItemIcon>
          <ListItemText>
            <Box className={classes.menuLink} component="a" href="mailto:soporte@parrotsoftware.io" target="_blank">
              {t('common:help.email')}
            </Box>
          </ListItemText>
        </StyledMenuItem>
      </StyledMenu>
    </li>
  );
}

SidebarLayoutItemMenu.propTypes = {
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  t: PropTypes.func,
  openMenu: PropTypes.bool,
};

export default SidebarLayoutItemMenu;
