import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  row: {
    width: '100%',
    maxWidth: 505,
    margin: '0 auto',
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(24)}`,
  },
}));

export default useStyles;
