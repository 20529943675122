import React, { Suspense } from 'react';

import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import FontFaceObserver from 'fontfaceobserver';

import { RecoilRoot } from 'recoil';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/900-italic.css';
import '@fontsource/courier-prime';
import 'keen-slider/keen-slider.min.css';

import '@experimental-components/MaterialPickersCalendar/calendar.css';
import '@experimental-components/MaterialPickersDaterange/dateRangePicker.css';
import '@experimental-components/MaterialPickersDaterangeV2/dateRangePickerV2.css';

import NotificationProvider from '@design-system/providers/NotificationProvider';
import LocaleProvider from 'providers/root/LocaleProvider';
import AuthProvider from 'providers/root/AuthProvider';

import history from 'utils/history';
import theme from 'utils/theme';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import configureStore from './configureStore';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Observe loading of Roboto (to remove 'Roboto', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Roboto', {});

// When Roboto is loaded, add a font-family using Roboto to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RecoilRoot>
        <LocaleProvider i18n={i18n}>
          <Suspense
            fallback={
              <Backdrop open>
                <CircularProgress color="secondary" />
              </Backdrop>
            }
          >
            <ConnectedRouter history={history}>
              <HelmetProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <NotificationProvider>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                    </NotificationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </HelmetProvider>
            </ConnectedRouter>
          </Suspense>
        </LocaleProvider>
      </RecoilRoot>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);

if (module.hot) {
  module.hot.accept(['./i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
