import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  logSmall: {
    width: 28,
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    padding: 0,
    margin: 0,
  },

  navLink: {
    color: '#FFFFFF',
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: '0.78125rem 1rem 0.78125rem 1.5rem',
    width: '100%',
    fontWeight: 'normal',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },

  navButton: {
    color: '#FFFFFF',
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(16),
    width: '100%',
    fontWeight: 'normal',
  },

  navLinkLabel: {
    width: '100%',
    margin: '0 8px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1.1,
  },

  navLinkActive: {
    fontWeight: theme.typography.fontWeightMedium,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftWidth: theme.typography.pxToRem(3),
    padding: `${theme.typography.pxToRem(12.5)} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(
      12.5,
    )} ${theme.typography.pxToRem(21)}`,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },

  navLinkEndIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  navLinkStartIcon: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  navLinkLabelComplementIcon: {
    display: 'flex',
    gap: '1rem',
  },

  spacer: {
    flex: '1 1 auto',
  },

  line: {
    width: 30,
    height: 'auto',
  },

  disabled: {
    pointerEvents: 'none',
  },

  menuLink: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },

  navLiButton: {
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  buttonCard: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '22px 12px',
    lineHeight: 1.5,
    height: theme.typography.pxToRem(62),
    width: '100%',
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.raisinBlack.main}`,
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',

    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: props => (props.isTestEnv ? 'rgba(0,0,0, .2)' : '#2D2D2D'),
      boxShadow: 'none',
    },

    '&:active': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },

  buttonCardActive: {
    '& > button': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  buttonCardCollapseMenu: {
    padding: 0,
    minWidth: '50px',
    border: 'none',
  },

  startIcon: {
    marginRight: theme.spacing(3),
  },
  startIconV2: {
    marginRight: 0,
  },
  collapseMenu: {
    paddingLeft: '0.9rem',
  },
}));

export default useStyles;
