import produce from 'immer';

import { getListPermissions, getActivesModules } from 'utils/auth';

import { refreshToken, getUser, getLicenseState, getLicenseStateChannel } from './actions';

export const initialState = {
  refreshToken: {
    error: null,
    fetching: false,
    fetched: false,
  },

  isError: null,
  isFetching: false,
  authenticated: false,
  user: null,
  permissions: [],
  stores: [],

  licenseState: {
    error: null,
    fetching: false,
    fetched: false,
    license: {},
    modules: [],
  },

  licenseStateChannel: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const authProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case refreshToken.REQUEST:
        draft.refreshToken.fetching = true;
        draft.refreshToken.error = false;
        draft.refreshToken.fetched = false;
        break;

      case refreshToken.SUCCESS:
        draft.refreshToken.fetching = false;
        draft.refreshToken.fetched = true;
        break;

      case refreshToken.FAILURE:
        draft.refreshToken.error = true;
        draft.refreshToken.fetching = false;
        draft.refreshToken.fetched = false;
        break;

      case getUser.REQUEST:
        draft.isFetching = true;
        draft.isError = false;
        draft.authenticated = false;
        draft.user = null;
        draft.permissions = [];
        break;

      case getUser.SUCCESS:
        draft.isFetching = false;
        draft.user = payload;
        draft.stores = payload?.stores;
        draft.permissions = getListPermissions(payload?.permissions);
        draft.authenticated = true;
        break;

      case getUser.FAILURE:
        draft.isFetching = false;
        draft.user = null;
        draft.authenticated = false;
        draft.permissions = [];
        break;

      case getUser.RESET:
        draft.isFetching = false;
        draft.user = null;
        draft.authenticated = false;
        draft.permissions = [];
        draft.stores = [];
        break;

      case getLicenseState.REQUEST:
        draft.licenseState = { ...initialState.licenseState, fetching: true };
        break;

      case getLicenseState.SUCCESS:
        draft.licenseState.fetching = false;
        draft.licenseState.fetched = true;
        draft.licenseState.license = payload;
        draft.licenseState.modules = getActivesModules(payload.modules);
        break;

      case getLicenseState.FAILURE:
        draft.licenseState.error = true;
        draft.licenseState.fetching = false;
        draft.licenseState.fetched = false;
        break;

      case getLicenseState.RESET:
        draft.licenseState = { ...initialState.licenseState };
        break;

      case getLicenseStateChannel.REQUEST:
        draft.licenseStateChannel = { ...initialState.licenseStateChannel, fetching: true };
        break;

      case getLicenseStateChannel.SUCCESS:
        draft.licenseStateChannel.fetching = false;
        draft.licenseStateChannel.fetched = true;
        draft.licenseState.license = payload;
        break;

      case getLicenseStateChannel.FAILURE:
        draft.licenseStateChannel.error = true;
        draft.licenseStateChannel.fetching = false;
        draft.licenseStateChannel.fetched = false;
        break;

      default:
        return draft;
    }
  });

export default authProviderReducer;
