import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getAuthToken, setAuthToken, setCookieUser } from 'utils/auth';

import { useQueryParams } from 'hooks';

import { authRefreshToken, authInternalSignIn } from './services';
import { AuthForm, AuthPayload } from './types';

export function useAuthSignIn() {
  const { queryParams } = useQueryParams<{ continue?: string }>();
  return useMutation({
    retry: 1,
    mutationFn: (payload: AuthPayload) => authInternalSignIn(payload),
    onSuccess: async data => {
      const { accessToken, ...profile } = data;

      setAuthToken({
        accessToken,
        refreshToken: '',
      });

      setCookieUser(profile);

      const validContinueParamater = queryParams.continue !== '/permissions-denied' && queryParams.continue !== '%0';

      if (queryParams.continue && validContinueParamater) {
        return window.location.replace(queryParams.continue);
      }

      return window.location.replace('/');
    },
  });
}

export function useAuthRefreshToken() {
  const refresh = getAuthToken()?.refreshToken;

  return useMutation({
    mutationFn: () => authRefreshToken({ refresh }),
    onSuccess: data => {
      if (!data) return;

      setAuthToken({
        accessToken: data.access,
        refreshToken: data.refresh,
      });
    },
  });
}

export function useResolver() {
  const { t } = useTranslation('signIn');

  const schema = yup.object().shape({
    usernameField: yup.string().email().trim().lowercase().required(t('signIn:form.fieldErrors.emailTextFieldError')),
    passwordField: yup.string().required(t('signIn:form.fieldErrors.passwordTextFieldError')),
    termsAndConditionsField: yup.boolean().oneOf([true]),
  });

  const resolver = yupResolver<AuthForm>(schema);

  return resolver;
}
