import { getAuthToken, getCookieUser } from 'utils/authTyped';

export const useAuth = () => {
  const tokens = getAuthToken();
  const cookieUser = getCookieUser();

  return {
    isAuthenticated: Boolean(tokens?.accessToken && cookieUser),
    use: cookieUser,
  };
};
