import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ParrotConnectRedBlack from '@experimental-components/IconsComponents/ParrotConnectRedBlack';
import ButtonPrimary from '@design-system/ButtonPrimary';
import InputTextFiled from '@design-system/TextField';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';

import { getDataAxiosError } from 'config/api';
import { AxiosError } from 'axios';

import { defaultPromoData } from 'utils/signIn';

// @ts-ignore
import pkg from '../../../package.json';

import useStyles from './styles';
import { useAuthSignIn, useResolver } from './hooks';
import { AuthForm, PromotionalLogin } from './types';

export function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [initPromoData, setInitPromoData] = useState<PromotionalLogin>({});

  const { t } = useTranslation('signIn');
  const classes = useStyles(initPromoData);
  const theme = useTheme();
  const isLoginResponsive = useMediaQuery(theme.breakpoints.down('md'));
  const resolver = useResolver();
  const { mutate, isLoading, isError, reset, error } = useAuthSignIn();
  const { register, handleSubmit, errors, watch } = useForm<AuthForm>({
    mode: 'onSubmit',
    resolver,
  });

  useEffect(() => {
    setDefaultPromoData();
  }, []);

  const setDefaultPromoData = () => {
    setInitPromoData({
      title: t(defaultPromoData.title),
      titleColor: defaultPromoData.titleColor,
      description: t(defaultPromoData.description),
      descriptionColor: defaultPromoData.descriptionColor,
      backgroundColor: defaultPromoData.backgroundColor,
    });
  };

  const handleSubmitValues = (data: AuthForm) => {
    mutate({ user: data.usernameField, password: data.passwordField });
  };

  const isNotInternalUser = (codeError: string) => {
    return codeError === 'permissions.AuthenticationFailed';
  };

  return (
    <div className={classes.loginContainer}>
      <section className={classes.promotionalSection}>
        <div className={classes.promoContent}>
          {initPromoData?.title && (
            <Typography className={classes.promoTitle} component="h1">
              {initPromoData?.title}
            </Typography>
          )}

          {!isLoginResponsive && initPromoData?.description && (
            <Typography className={classes.promoDescription} component="h2">
              {initPromoData?.description}
            </Typography>
          )}

          {initPromoData?.url && initPromoData?.urlTitle && (
            <Typography className={classes.promoUrl} component="a">
              <Link color="inherit" href={initPromoData.url} rel="noopener" target="_blank">
                {initPromoData.urlTitle}
              </Link>
            </Typography>
          )}
        </div>
      </section>

      <section className={classes.formSection}>
        <div className={classes.iconParrot}>
          <ParrotConnectRedBlack />
        </div>

        <form onSubmit={handleSubmit(handleSubmitValues)}>
          <Collapse in={isError}>
            <Alert data-cy="notificationAlert" onClose={() => reset()} severity="error">
              {t(
                `signIn:errors.${
                  // @ts-ignore
                  isNotInternalUser(getDataAxiosError(error as AxiosError)?.errors[0]?.code)
                    ? 'notUserInternal'
                    : 'emailAndPassword'
                }`,
              )}
            </Alert>
          </Collapse>

          <div className={classes.formContent}>
            {!isLoginResponsive && (
              <Typography className={classes.formTitle} component="h3">
                {t('signIn:form.title')}
              </Typography>
            )}

            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <InputTextFiled
                  autoComplete="off"
                  data-cy="usernameField"
                  disabled={isLoading}
                  error={Boolean(errors?.usernameField)}
                  errorMsg={errors?.usernameField?.message}
                  fullWidth
                  id="usernameField"
                  inputProps={{ maxLength: 255 }}
                  inputRef={register({
                    required: t('signIn:form.fieldErrors.emailTextFieldError'),
                  })}
                  label={t('signIn:form.email')}
                  name="usernameField"
                  placeholder={t('signIn:form.placeHolderEmail')}
                  type="text"
                />
              </Grid>

              <Grid item xs={12}>
                <InputAdornmentFiled
                  data-cy="passwordField"
                  disabled={isLoading}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="passwordField"
                        disabled={isLoading}
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={event => event.preventDefault()}
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={Boolean(errors?.passwordField)}
                  errorMsg={errors?.passwordField?.message}
                  fullWidth
                  id="passwordField"
                  inputProps={{ maxLength: 255 }}
                  inputRef={register({
                    required: t('signIn:form.fieldErrors.passwordTextFieldError'),
                  })}
                  label={t('signIn:form.password')}
                  name="passwordField"
                  placeholder={t('signIn:form.placeHolderPassword')}
                  type={showPassword ? 'text' : 'password'}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.formButton}>
                  <ButtonPrimary
                    data-cy="submitButton"
                    disabled={isLoading || !(watch('usernameField')?.length && watch('passwordField')?.length)}
                    fullWidth={isLoginResponsive}
                    loading={isLoading}
                    type="submit"
                  >
                    {t('signIn:form.initSession')}
                  </ButtonPrimary>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>

        <div className={classes.footer}>
          {!isLoginResponsive && (
            <Typography className={classes.urlParrot} color="initial" variant="h6">
              <Link color="inherit" fontSize={16} href="https://pos.parrotsoftware.io/" rel="noopener" target="_blank">
                {t('common:parrotUrl')}
              </Link>
            </Typography>
          )}

          <Typography className={classes.copyright} variant="body2">
            {t('signIn:messages.copyright', { version: `V${pkg.version}`, date: dayjs().year() })}
          </Typography>
        </div>
      </section>
    </div>
  );
}

export default SignIn;
