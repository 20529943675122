// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import SignIn from 'containers/SignIn';
import SignOut from 'containers/SignOut';
import AccessDeniedAdmin from 'containers/AccessDeniedAdmin/Loadable';

import Settings from 'containers/Settings/Loadable';
import SettingsPromotionalLogin from 'containers/SettingsPromotionalLogin/Loadable';
import SettingsAppVersions from 'containers/SettingsAppVersions/Loadable';
import SettingsForceAppVersions from 'containers/SettingsForceAppVersions/Loadable';
import NotFound from 'components/NotFound';
import WithoutStore from 'components/WithoutStore';
import LoggedOutPage from 'components/LoggedOutPage';

import AppRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { HomeRedirect } from './HomeRedirect';

function App() {
  const { i18n } = useTranslation();

  return (
    <>
      <Helmet defaultTitle="Parrot-Config" htmlAttributes={{ lang: i18n.language }} titleTemplate="%s - Parrot-Config">
        <meta content="Parrot-Config" name="Parrot-Config" />
      </Helmet>

      <Switch>
        <Route component={HomeRedirect} exact path="/" />
        <Route component={WithoutStore} exact path="/without-store" />
        <PublicRoute component={SignIn} exact path="/signin" />
        <Route component={LoggedOutPage} exact path="/logged-out" />
        <Route component={SignOut} exact path="/signout" />

        <AppRoute component={Settings} exact path="/settings" />
        <AppRoute component={SettingsPromotionalLogin} exact path="/settings/promotional-login" />
        <AppRoute component={SettingsAppVersions} exact path="/settings/app-versions/:actionType(create|edit)?" />
        <AppRoute component={SettingsForceAppVersions} exact path="/settings/app-versions/force-update" />

        <AppRoute component={AccessDeniedAdmin} exact path="/permissions-denied" />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
