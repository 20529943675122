import { Button, ButtonProps, CircularProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const ButtonPrimaryBase = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: '12px 16px',
    border: 'none',
    lineHeight: '16px',
    height: theme.typography.pxToRem(42),
    minWidth: theme.typography.pxToRem(133),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: '#2D2D2D',
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.common.black,
    },

    '&:focus': {
      border: `1px solid ${theme.palette.common.black}`,
    },

    '&$disabled': {
      color: theme.palette.common.white,
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.common.white,
  },

  sizeSmall: {
    padding: 16,
  },
}))(Button);

interface Props extends ButtonProps {
  loading?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
}

function ButtonPrimary(props: Props) {
  const { loading, isLoading, disabled, isDisabled } = props;

  return (
    <ButtonPrimaryBase
      disabled={disabled || isDisabled}
      disableElevation
      disableRipple
      endIcon={(loading || isLoading) && <CircularProgress color="inherit" size={18} />}
      {...props}
    />
  );
}

export default ButtonPrimary;
