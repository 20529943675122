import React from 'react';
import DividerMUI from '@mui/material/Divider';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  divider: {
    background: theme.palette.darkGrey[15],
  },
}));

function Divider({ ...others }) {
  const classes = useStyles();

  return <DividerMUI classes={{ root: classes.divider }} {...others} />;
}

export default Divider;
