import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  colorInherit?: string;
  size?: string | number;
}
const Success = forwardRef(function Success(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#27AE60', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M21.7521 4.26635C22.0796 4.62475 22.083 5.20959 21.7597 5.57265L9.15555 19.7252C8.99897 19.901 8.78538 20 8.5625 20C8.33962 20 8.12603 19.901 7.96946 19.7252L2.24029 13.2922C1.91696 12.9292 1.92036 12.3443 2.24789 11.9859C2.57542 11.6275 3.10305 11.6313 3.42638 11.9944L8.5625 17.7615L20.5736 4.27478C20.897 3.91172 21.4246 3.90795 21.7521 4.26635Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default Success;
