import { memo, useEffect } from 'react';
import { compose } from 'redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import FeatureProvider from 'FeatureProvider';
import { useQueryParams } from 'hooks';

import { useUser } from 'containers/Users';

import { getListPermissions, setAuthToken, setCookieUser } from 'utils/auth';

interface Params {
  token: string;
  storeUuid: string;
  userUuid: string;
}

interface Props {
  children: React.ReactNode;
}

export function AuthProvider({ children }: Props) {
  const { queryParams } = useQueryParams<Params>();
  const { data: user, isLoading: isLoadingUser } = useUser();

  const { token, userUuid } = queryParams;

  useEffect(() => {
    if (token && userUuid) {
      setAuthToken({
        accessToken: token,
        refreshToken: '',
      });
      setCookieUser({
        uuid: userUuid || user?.uuid || '',
        email: user?.email || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
      });
      window.location.replace('/');
    }
  }, [token, userUuid]);

  if (isLoadingUser) {
    return (
      <Backdrop open>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  const features = user ? [...getListPermissions(user.permissions)] : [];

  return <FeatureProvider features={{ permissions: features }}>{children}</FeatureProvider>;
}

export { default as useAuth } from './useAuth';
export default compose(memo)(AuthProvider);
