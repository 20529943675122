import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

type PropsStyle =
  | {
      titleColor?: string | undefined;
      descriptionColor?: string | undefined;
      urlTitleColor?: string | undefined;
      backgroundColor?: string | undefined;
      coverUrl?: string | undefined;
    }
  | undefined;

const useStyles = makeStyles((theme: Theme) => ({
  loginContainer: {
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  promotionalSection: (props: PropsStyle | undefined) => ({
    backgroundColor: props?.backgroundColor ? `#${props?.backgroundColor}` : theme.palette.primary.main,
    backgroundImage: `url(${props?.coverUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gridColumn: '1/span 3',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '14%',
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      justifyContent: 'center',
      backgroundPositionY: '35%',
      minHeight: theme.typography.pxToRem(155),
    },
  }),

  overlay: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5)',
  },

  promoContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    maxWidth: theme.typography.pxToRem(540),

    [theme.breakpoints.down('md')]: {
      maxWidth: theme.typography.pxToRem(400),
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  },

  promoTitle: (props: PropsStyle) => ({
    color: `#${props?.titleColor}`,
    fontSize: theme.typography.pxToRem(64),
    fontWeight: 500,
    lineHeight: 1.2,

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(21),
      textAlign: 'center',
    },
  }),

  promoDescription: (props: PropsStyle) => ({
    color: `#${props?.descriptionColor}`,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    lineHeight: 1.7,
  }),

  promoUrl: (props: PropsStyle) => ({
    color: `#${props?.urlTitleColor}`,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    textDecoration: 'underline',

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  }),

  formSection: {
    gridColumn: '4/span 5',
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(8),
    maxWidth: theme.typography.pxToRem(650),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      height: '100%',
    },
  },

  iconParrot: {
    display: 'flex',
    height: theme.typography.pxToRem(55),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },

  formContent: {
    paddingBlock: theme.spacing(3),
  },

  formTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },

  termsAndConditions: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  rootLinkTermsConditions: {
    color: theme.palette.info.main,
  },

  formButton: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: theme.spacing(2),
    height: theme.typography.pxToRem(55),
  },

  urlParrot: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },

  copyright: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,

    [theme.breakpoints.down('md')]: {
      color: theme.palette.darkGrey[50],
      fontWeight: theme.typography.fontWeightRegular,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
