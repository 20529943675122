import { useState } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import LogOutOutline from '@experimental-components/IconsComponents/LogOutOutline';

// import Reports from '@experimental-components/IconsComponents/PrincipalMenu/Reports';
import Settings from '@experimental-components/IconsComponents/PrincipalMenu/Settings';

import SidebarLayout from 'components/SidebarLayout';

import useUserStore from 'hooks/useUserStore';
import useViewPanel from 'hooks/useViewPanel';
import useContentLayout from 'hooks/useContentLayout';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

export function AppFrame({ children, t }) {
  const theme = useTheme();
  const location = useLocation();
  const { openDrawer, openMdDrawer } = useSidebarLayout();

  const { user } = useUserStore();
  const { setOpen } = useViewPanel();
  const { setValue } = useContentLayout();
  const [changeColorIcon, setChangeColorIcon] = useState(null);

  function handleResetViewPanel() {
    setOpen(false);
    setValue(0);
    setChangeColorIcon(null);
  }

  const handleMouseEnter = item => {
    setChangeColorIcon(state => ({ ...state, [item]: true }));
  };
  const handleMouseLeave = () => {
    setChangeColorIcon(null);
  };

  return (
    <SidebarLayout>
      <SidebarLayout.Sidebar>
        <SidebarLayout.Section mb={3}>
          {/* <SidebarLayout.Item
            exact={false}
            isActive={(match, locationRef) => {
              return locationRef.pathname.startsWith('/dashboard');
            }}
            label={t('common:nav.dashboard')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/dashboard')) handleMouseEnter('dashboard');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/dashboard')) handleMouseLeave();
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Reports
                color={changeColorIcon?.dashboard ? theme.palette.secondary.main : theme.palette.common.white}
                size={24}
              />
            }
            to="/dashboard"
          /> */}
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.setting')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/settings')) handleMouseEnter('settings');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/settings')) handleMouseLeave();
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Settings
                color={changeColorIcon?.settings ? theme.palette.secondary.main : theme.palette.common.white}
                size={24}
              />
            }
            to="/settings"
          />
        </SidebarLayout.Section>

        <SidebarLayout.Spacer />

        <SidebarLayout.Divider variant="middle" />

        <SidebarLayout.Section>
          <SidebarLayout.Item
            label={
              !user?.firstName || (!user?.firstName && !user.lastName)
                ? t('common:auth.signOut')
                : `${user.firstName} ${user.lastName}`
            }
            onClick={handleResetViewPanel}
            onMouseEnter={() => handleMouseEnter('logout')}
            onMouseLeave={() => handleMouseLeave('logout')}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <LogOutOutline
                color={changeColorIcon?.logout ? theme.palette.secondary.main : theme.palette.common.white}
                size={24}
              />
            }
            to="/signout"
          />
        </SidebarLayout.Section>
      </SidebarLayout.Sidebar>

      <SidebarLayout.Content>{children}</SidebarLayout.Content>
    </SidebarLayout>
  );
}

AppFrame.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func,
};

export default withTranslation()(AppFrame);
